<template>
  <a-tag :class="$style.avatarBox">
    <img :src="src" alt="" @error="handleError" :class="$style.imageBox" />
    <span :class="$style.label">{{ name }}</span>
  </a-tag>
</template>
<script>
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import defaultImage from '@/assets/images/defaultNone.png';
@Component({})
export default class avatar extends Vue {
  @Prop({ type: String, default: '' }) img;
  @Prop({ type: String, default: '匿名' }) name;
  src = defaultImage;
  handleError() {
    this.src = defaultImage;
  }
  @Watch('img', { immediate: true })
  changeImg(newVal) {
    this.src = newVal;
  }
}
</script>
<style lang="less" module>
.avatarBox {
  .imageBox {
    vertical-align: middle;
    display: inline-block;
    width: 22px;
    height: 22px;
    line-height: 22px;
    border-radius: 50%;
    text-align: center;
    margin-right: 4px;
    background-color: var(--x-modal-select-avatar);
    color: #fff;
    font-size: 12px;
    -o-object-fit: cover;
    object-fit: cover;
  }
  .label {
    vertical-align: middle;
  }
}
</style>
