import request from '../request';

const serverName = '/iot-kcloud/kolb/authority';

// 获取当前用户的权限
export function getUserRole() {
  return request(`${serverName}/getLoginUserRole`, {
    method: 'GET',
  });
}

// 获取当前用户的权限 下的权限列表
export function getKolbUserList(data) {
  return request(`${serverName}/kolbUserList`, {
    method: 'GET',
    body: data,
  });
}

// 获取当前用户可以管理的用户列表
export function getCanChooseUserList(data) {
  return request(`${serverName}/listCanChooseKolbUser`, {
    method: 'GET',
    body: data,
  });
}

// 提交角色编辑
export function setRole(data) {
  return request(`${serverName}/settingRole`, {
    method: 'PUT',
    body: data,
  });
}

// 获取角色管辖列表
export function getUserList(memberId) {
  return request(`${serverName}/kolbUserManager/${memberId}`, {
    method: 'GET',
  });
}
